import React, { useState} from 'react';
import { Carousel, Row, Col, Container, Image } from 'react-bootstrap';
import Imag from '../Images/img_1.jpg';
import Imag1 from '../Images/img_2.jpg';
import Imag2 from '../Images/img_3.jpg';
import T1 from '../Images/1.jpg';
import T2 from '../Images/2.jpg';
import T3 from '../Images/6.jpg';
import T4 from '../Images/4.jpg';
import T5 from '../Images/7.jpg';
import T6 from '../Images/8.jpg';
import T7 from '../Images/9.jpeg';
import T8 from '../Images/10.jpeg';
import T9 from '../Images/11.jpeg';
import T10 from '../Images/12.jpeg';
import T11 from '../Images/13.jpeg';
import T12 from '../Images/14.jpeg';
import './ArtGallery.css'

const ArtGallery = () => {
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    return (
        <div className="">
      <Carousel activeIndex={index} onSelect={handleSelect} classname="divFix">
      <Carousel.Item>
          <Image
            className="d-block w-100"
            src={Imag2}
            alt="First slide"
            
          />
                 <Carousel.Caption>
            <h3 className="car-head">مجھ سے بہتر تو بہت آئینگے</h3>
            <h3 className="car-head">کوئی مجھ سا نہیں پیدا ہوگا</h3>
            <p className="car-head1">صبا اکبر آبادی</p>
          </Carousel.Caption>
        </Carousel.Item>
 
        <Carousel.Item>
          <Image
            className="d-block w-100"
            src={Imag1}
            alt="First slide"
            
          />
          <Carousel.Caption>
            <h3 className="car-head">ارشاد علی , اشفاق احمد خان , سلطان جمیل نسیم , محمد علی</h3>
            <p className="car-head1">ریڈیو پاکستان حیدآباد ۱۹۵۸</p>
            </Carousel.Caption>
         </Carousel.Item>
         <Carousel.Item>
          <Image
            className="d-block w-100"
            src={Imag}
            alt="First slide"
            
          />
        </Carousel.Item> 
      </Carousel>
  <Container>
    <div className="heading-1">
      <h1>
      <span className="main-head">پرانی یادیں</span></h1>
      </div>
      
  <Row>
  <Col xs={6} md={4} className="margin-1" >
      <Image src={T1} fluid />
    </Col>
    <Col xs={6} md={4} className="margin-1">
      <Image src={T2} fluid />
    </Col>
    <Col xs={6} md={4} className="margin-1">
      <Image src={T3} fluid />
    </Col>
    <Col xs={6} md={4} className="margin-1">
      <Image src={T4} fluid />
    </Col>
    <Col xs={6} md={4} className="margin-1">
      <Image src={T5} fluid />
    </Col>
    <Col xs={6} md={4} className="margin-1">
      <Image src={T6} fluid />
    </Col>
    <Col xs={6} md={4} className="margin-1" >
      <Image src={T10} fluid />
    </Col>
    <Col xs={6} md={4} className="margin-1">
      <Image src={T11} fluid />
    </Col>
    <Col xs={6} md={4} className="margin-1">
      <Image src={T8} fluid />
    </Col>
    <Col xs={6} md={4} className="margin-1">
      <Image src={T7} fluid />
    </Col>
    <Col xs={6} md={4} className="margin-1">
      <Image src={T12} fluid />
    </Col>
    <Col xs={6} md={4} className="margin-1">
      <Image src={T9} fluid />
    </Col>

  </Row>
</Container>
</div>
    );
}

export default ArtGallery; 