
import React from 'react'

export const CheckBox = props => {
    return (
      <li>
       <input type="checkbox" onClick={props.handleCheckChildElement} checked={props.isChecked} value={props.title} /> {props.title}
      </li>
    )
}

export default CheckBox