import React, { useState } from "react";
import "./panel.css";
import CheckBox from "./CheckBox";
import axios from 'axios';


// Adding properties for the PDF
const Properties = [
  { id: 1, title: "Afsana-o-Novel", isChecked: false },
  { id: 2, title: "Dramay-o-Mazameen", isChecked: false },
];

const UploadForm = () => {
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [pdfData, setpdfData] = useState('');
  const [pdfName, setpdfName] = useState('');
  const [Type, setType] = useState("");
  const [pdfFileError, setPdfFileError] = useState('');

  const fileType = ['application/pdf'];

  const selectFile = (event) => {
    let selectFiles = event.target.files[0];
    console.log(event.target.files);
    setpdfName(selectFiles.name);
    if (selectFiles) {
      if (selectFiles && fileType.includes(selectFiles.type)) {
        let render = new FileReader();
        render.readAsDataURL(selectFiles);
        render.onloadend = (e) => {
          setpdfData(e.target.result);
          setPdfFileError('');
        }

      }
      else {
        setpdfData(null);
        setPdfFileError('Please select valid pdf file')
      }
    }
    else {
      console.log('select your file')
      console.log(pdfFileError)
    }

  }

  const upload = (e) => {
    e.preventDefault();
    let fileDetails = {
      Name: pdfName,
      Type: Type,
      PdfData: pdfData
    }
    console.log(fileDetails)
    alert("Your document is uploaded, Kindly go to the selected category and check the document")
    axios.post("/uploadPdf", fileDetails, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.log("dd", response.data)
        alert("Your document is uploaded, Kindly go to the selected category and check the document")
      })
      .catch(() => {
        console.log("aaa")
      })
  }




  const handleCheckChildElement = (event) => {
    event.preventDefault();
    alert(event.target.value + " is selected as the document category \n If Wrong please refresh the page and upload again \n Please select PDF's only")
    Properties.filter((Property) => {
      event.preventDefault();
      if (Property.title === event.target.value)
        console.log(event.target.value);
      setType(event.target.value);
    });
  };





  return (
    <div className="container">
      <div className="row">
        {currentFile && (
          <div className="progress">
            <div
              className="progress-bar progress-bar-info progress-bar-striped"
              role="progressbar"
              aria-valuenow={progress}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: progress + "%" }}
            >
              {progress}%
            </div>
          </div>
        )}
        <div class="col-md-6 col-sm-12 m-auto">
          <div class="custom-file mb-3">
            <label className="btn btn-default">
              <input
                type="file"
                onChange={selectFile}
                style={{ color: "white" }}
              />
            </label>
          </div>
        </div>
        <div class="col-md-3 col-sm-4 m-auto">
          <button
            className="btn btn-success"
            disabled={!Type}
            onClick={upload}
          >
            Upload
          </button>
        </div>
        <div class="col-md-12 col-sm-4 m-auto">
          <div className="alert alert-light" role="alert">
            {message}
          </div>
        </div>
      </div>
      <br />
      <div className="Checkboxes">
        <h3>Select the Section in which PDF is to be Uploaded</h3>
        <p>Upload only PDF</p>
        <ul>
          {Properties.map((Properties) => {
            return (
              <CheckBox
                handleCheckChildElement={handleCheckChildElement}
                {...Properties}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default UploadForm;

// UploadFile.uploadDetails(ObjectName, async (event) => {
//   setProgress(Math.round((100 * event.loaded) / event.total));
// })
//   .then((response) => {
//     console.log(response.data);
//   })
//   .catch((error) => {
//     console.log(error);
//   });
