import React, { useState } from 'react';
import axios from "axios";
import { useHistory } from "react-router-dom"
import './Admin.css'


const FormPage = () => {
  let [email, setemail] = useState('');
  let [password, setpassword] = useState('');
  let history = useHistory();


  const Confirmation = (event) => {
    event.preventDefault();
    console.log(email, password);
    let data = {
      email: email,
      password: password,
    }
    axios.post('/login', data)
      .then(res => {
        if (res.data) {
          console.log("dddd")
          history.push('/Panel')

        }
        else {
          console.log("ssssss")
          history.push('/')
        }
      }
      )
      .catch(error => {
        console.log(error)
      })
  }
  return (
    <div className="App">
      <div className="container">
        <div className="outer">
          <div className="inner">
            <form onSubmit={Confirmation}>

              <h3>Log in</h3>

              <div className="form-group">
                <label>Email</label>
                <input type="email" className="form-control" placeholder="Enter email" onChange={(ev) => setemail(ev.target.value)}></input>
              </div>

              <div className="form-group">
                <label>Password</label>
                <input type="password" className="form-control" placeholder="Enter password" onChange={(ev) => setpassword(ev.target.value)} />
              </div>

              <div className="form-group">
                <div className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" id="customCheck1" />
                  <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
                </div>
              </div>

              <button type="submit" className="btn btn-dark btn-lg btn-block">Sign in</button>

            </form>
          </div>
        </div></div>
    </div>
  );
}

export default FormPage;