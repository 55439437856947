import React from 'react'
import { Container, Row, Col} from 'react-bootstrap'
import './novel.css';
import ReactPlayer from "react-player/lazy";

const Writer1 = () => {
    return (
        <div className="app">
            <div className="head-1">
                <h1 className="heads1">(Videos)ویڈیوز</h1>
            </div>
            <Container>
                <Row>
                <Col>
                <div className="Videos">
                    <ReactPlayer
                                    width="100%"
                        url="https://youtu.be/IB2j9K9bZj8" controls
                    />
                </div>
                </Col>
                <Col>
                <div className="Videos">
                    <ReactPlayer
                        url="https://www.youtube.com/embed/cIVvg-MChRI" controls
                    />
                </div>
                </Col>
                </Row>
                <Row>
                <Col>
                <div className="Videos">
                    <ReactPlayer
                        url="youtube.com/watch?v=mKETAmDLNKk&feature=youtu.be" controls
                    />
                </div>
                </Col>
                <Col>
                <div className="Videos">
                    <ReactPlayer
                    width="100%"
                        url="https://www.youtube.com/embed/lNM7ZFq0WTo" controls
                    />
                </div>
                </Col>
                </Row>
                <Row>
                <Col>
                <div className="Videos">
                    <ReactPlayer
                    width="100%"
                        url="https://youtu.be/9HCMznZHwMA" controls
                    />
                </div>
                </Col>
                <Col>
                <div className="Videos">
                    <ReactPlayer
                        url="https://youtu.be/LNNvF2t_ZOM" controls
                    />
                </div>
                </Col>
                </Row>
                <Row>
                <Col>
                <div className="Videos">
                    <ReactPlayer
                    width="100%"
                        url="https://youtu.be/bRAVudiqUuE" controls
                    />
                </div>
                </Col>
                <Col>
                <div className="Videos">
                    <ReactPlayer
                        url="https://youtu.be/bZPeh6v50vA" controls
                    />
                </div>
                </Col>
                <Col>
                <div className="Videos">
                    <ReactPlayer
                        url="https://youtu.be/xW7AeI_j2xY" controls
                    />
                </div>
                </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Writer1;