import React from 'react';
import './App.css';
import Layout from './Components/Layout';
import { Route,Switch,BrowserRouter, } from 'react-router-dom';
import Panel from './Components/Panel'
import Landing_page from './Components/Landing_page'
import Admin from './Components/Admin'
import ArtGallery from './Components/ArtGallery'
import Novel from './Components/Novel'
import home from './Components/Home';
import Videos from './Components/Videos'
import Drama from './Components/Drama_etc'
import SabaAkbar from './Components/Saba-Akbar'
import Books from './Components/books';


function App() {
  return (
    <BrowserRouter>
     <Layout>
       <Switch>
      <Route path='/'exact component ={Landing_page}/>
      <Route path='/books' component = {Books}/> 
      <Route path='/Taruf' component={home}/>
      <Route path='/Admin' component={Admin}/>
      <Route path ='/Panel' component = {Panel} />
      <Route path='/ArtGallery' component= {ArtGallery}/>
      <Route path='/Videos' component={Videos}/>
      <Route path='/Drama-o-mazameen' component={Drama}/>
      <Route path='/Afsana-o-Novel' component={Novel}/>
      <Route path='/Saba-Akbar-Abadi' component={SabaAkbar}/>
      </Switch>
    </Layout>
    
    </BrowserRouter>
  );
}

export default App;
