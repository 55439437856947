import React from 'react';
import { Carousel, Row, Col, Container, Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import './Landing_page.css'
import './novel.css'
import Title from '../Images/Title-image.jpeg';
import Imag2 from '../Images/img_3.jpg';
import T4 from '../Images/1.jpg';
import T5 from '../Images/2.jpg';
import T6 from '../Images/6.jpg';
import T7 from '../Images/4.jpg';
import T15 from '../Images/7.jpg';
import T8 from '../Images/8.jpg';
import pdf1 from "../Images/1st_pdf.jpeg";


const ArtGallery = () => {
  let Names = ["گھر کا راستہ.pdf", "ڈرامہ دعوی.pdf", "میں آئینہ ہو.pdf", "کہانی امید کی.pdf"]
  let Data = Names.map((elements) => (
    <Col md={{ span: 3, offset: 0 }} xs={{ span: 6, offset: 0 }} >
    <div className="pdf-cards">
      <Image src={pdf1} rounded className="img-fluid" />
      <div className="details">
        <p className="article-name">{elements.slice(0, -4)}</p>
        <Button className="buttons">
            <Link to={{ pathname: '/books', state: { Name: elements } }}>Read Now</Link>
          </Button>
    </div>
    </div>
  </Col>))
  return (
     <div className="background">
      <Carousel classname="divFix">
          <Image
            className="d-block w-100"
            src={Imag2}
            alt="First slide"

          />
          <Carousel.Caption>
            <h3 className="car-head2">مجھ سے بہتر تو بہت آئینگے </h3>
            <h3 className="car-head2">کوئی مجھ سا نہیں پیدا ہوگا</h3>
            <p className="car-head1">صبا اکبر آبادی</p>
          </Carousel.Caption>
        </Carousel>
      <Container>
        <div className="opacity-1">
          <Row>
            <Col md={4}><img className="Title-pic1" src={Title} alt="" /></Col>
            <Col md={{ span: 7, offset: 1 }}><p className="p-over">سلطان جمیل نسیم ایک افسانہ نگار ہی نہیں ایک صاحب الرائے دانشور بھی تھے۔ وہ ادب کے بارے میں ایک مخصوص تصور اور نظریہ رکھتے تھے۔ وہ اپنے افسانوی مجموعے کے دیباچے میں لکھتے ہیں ’میں یہ بات نہیں کہوں گا کہ اپنے افسانوں میں زندگی کے حقائق بیان کرتا ہوں اس لیے کہ ہر افسانہ نگار اپنے موضوعات اپنے اطراف میں بکھری ہوئی زندگی کے رنگارنگ حقائق سے ہی چنتا ہے۔ میں نے کسی چھوٹے یا معمولی واقعے پر لکھا ہو یا کسی خاص اور اہم حقیقت کو موضوع بنایا ہو خدا کا شکر ہے کبھی بے معنی گنجلک پن کا شکار نہیں رہا۔ عصری تقاضوں سے غفلت نہیں برتی اور سماجی حقیقت نگاری کو ہمیشہ ترجیح دی‘ ۔ ان الفاظ سے صاف واضح ہے کہ سلطان جمیل نسیم کے لیے اپنے افسانوں کا ابلاغ اور قاری سے ایک جینوئن اور مخلص رشتہ کتنی اہمیت کے حامل تھے۔</p></Col>
          </Row>
        </div>
      </Container>
      <Container>

        <Row>
          <Col xs={6} md={4} className="margin" >
            <Image src={T8} fluid />
          </Col>
          <Col xs={6} md={4} className="margin">
            <Image src={T4} fluid />
          </Col>
          <Col xs={6} md={4} className="margin">
            <Image src={T5} fluid />
          </Col>
          <Col xs={6} md={4} className="margin">
            <Image src={T6} fluid />
          </Col>
          <Col xs={6} md={4} className="margin">
            <Image src={T7} rounded fluid />
          </Col>
          <Col xs={6} md={4} className="margin">
            <Image src={T15} rounded fluid />
          </Col>
        </Row>
      </Container>
      <article className="afsana_article">
        <div className="afsana_top">
        <Container>
        <h1 className="heading">تحریریں</h1>
        <Row>
          {Data}
        </Row>
      </Container>
        </div>
      </article>
    </div>
  );
}

export default ArtGallery;