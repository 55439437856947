import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Container, Button, Col, Row, Image } from "react-bootstrap";
import "./novel.css";
import LoadingScreen from "react-loading-screen";
import pdf1 from "../Images/cover.jpg"
import LoadingLogo from '../Images/Title-image.jpeg'

const SabaAkber = () => {
  const [PdfNames, setPdfNames] = useState("");

  useEffect(() => {
    async function getPdfs() {
      let data;
      await axios.post("/getpdf", { Type: "Saba-Akber-Section" }).then((res) => {
        console.log(res.data.Pdf);
        data = res.data.Pdf;
        setPdfNames(data);
      });
    }
    getPdfs();
  }, []);
  console.log(PdfNames);

  let Data = (
    <LoadingScreen
      loading={true}
      bgColor="#f1f1f1"
      spinnerColor="#9ee5f8"
      textColor="#676767"
      logoSrc={LoadingLogo}
      text="Saba Akbar Abadi"
    ></LoadingScreen>
  );
  if (PdfNames) {
    Data = PdfNames.map((elements) => (
      <Col md={{ span: 3, offset: 0 }} xs={{ span: 6, offset: 0 }}>
        <div className="pdf-cards">
          <Image src={pdf1} rounded className="img-fluid" />
          <div className="details">
            <p className="article-name">{elements.Name.slice(0, -4)}</p>
            <Button className="buttons">
              <Link to={{ pathname: "/books", state: { Name: elements.Name } }}>
                Read Now
              </Link>
            </Button>
          </div>
        </div>
      </Col>
    ));
  }

  return (
    <div>
      <article className="afsana_article">
        <Container>
          <h1 className="heading">تظمین غالِب</h1>
          <Row>{Data}</Row>
        </Container>
      </article>
    </div>
  );
};
export default SabaAkber;
