import React from 'react';
import Title from '../Images/Title-image.jpeg';
import './Home.css';
import { Container, Row, Col } from 'react-bootstrap';

const Home = () => {
    return (

        <div className="BackgroundPic">
            <Container>
                <div className="opacity">
                    <Row>
                        <Col md={4}><img className="Title-pic" src={Title} alt="" /></Col>
                        <Col md={{ span: 7, offset: 1 }}> <p className="p-over">سلطان جمیل نسیم ممتاز افسانہ نگار ،صداکار ،اداکار اور ڈراما نگار تھے۔ان کے والد صباکبر آبادی اردو کے مشہور و معروف شاعر تھے

                        ولادت

                        خواجہ سلطان جمیل ولد خواجہ محمد امیر 14 اگست 1935ء آگرہ میں پیدا ہوئے۔

                        عملی زندگی	ترميم

                        جامعہ کراچی سے ایم اے کیا، 1954ء میں میٹرک کے بعد مختلف ملازمتیں کیں، 1960ء کو یو بی ایل سے وابستہ ہوئے 1995ء کو سبکدوش ہوئے، لکھنے کی ابتداء 1951ء میں کی اور پہلی کہانی لکھی۔


                        افسانوں کے مجموعے ی

                        کھویا ہوا آدمی (1986ء)
                        سایہ سایہ دھوپ (1989ء)
                        ایک شام کا قصہ (2000ء)
                        میں آئینہ ہوں (2002ء)
                        ڈرامے یہ شائع ہوئے،

                        جنگل زمین خوشبو (1999ء)

                        ریڈیو پاکستان سے وابستگی

                        مختلف موضوعات پر مضامین جن میں ریڈیو سے نشر ہونے والے پاکستانی۲۶افسانہ نگاروں کے بارے میں اظہارِ رائے کیا گیا ہے۔ ہر افسانہ نگار کے بارے میں اِس ہفت روزہ پروگرام کا دورانیہ 65 منٹ ہوتا تھا۔

                        ریڈیو پاکستان کراچی سے ہی مسلسل تین سال تک ایک ڈرامائی فیچر ’’حامد منزل‘‘ کے نام سے تحریر کیا۔

                        مقالات

                        ان کے بارے میں سندھ یونیورسٹی سے ایم اے کی سطح پر دو مقالے لکھے گئے ۔

                        کینڈا میں مقیم مشہور و معروف ناول نگار جناب اکرام بریلوی کی کتاب’’سلطان جمیل نسیم کے افسانے تنقید اور تجزیہ‘‘ کے عنوان سے حال ہی میں شائع ہوئی ہے۔

                        وفات

30 جولائی 2020ء کو وفات پاگئے، جبکہ ان کی تدفین آخری آرام گاہ کراچی میں سخی حسن مین ہے۔</p></Col>
                    </Row>
                </div>
            </Container>
        </div>


    )
}
export default Home;


