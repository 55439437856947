import React from "react"
import { Link } from 'react-router-dom';
import './footer.css'



const Footers = () => {
  return(
    <div className='footer mt-auto py-3 text-white bg-f'>
        <div className='container'>
          <div className="Footer"> 
          <h6>Copyright © 2020 Sultanjamilnasim</h6>      
          <Link to="/Admin"><h6>FOR ADMIN USE ONLY</h6></Link>
          </div>
        </div>
    </div>
    )
  }

export default Footers  