import React from 'react'
import Navbar from './navbar'
import Footer from './footer'
import './layout.css'

const Layout = (props) => {

  return(
    <div>
      <header className="head fixed-top">
        <Navbar /> 
      </header>
      <main className= "main-content">
        {props.children}
      </main>
      <footer className= "footer-set">
        <Footer />
      </footer>
    </div>
  )
}
export default Layout