import React, { useEffect, useState } from 'react'
import axios from 'axios'
import LoadingScreen from 'react-loading-screen';
import LoadingLogo from '../Images/Title-image.jpeg'


import { Viewer , Worker} from '@react-pdf-viewer/core';

// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

// Create new plugin instance



const Books = (props) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const [GetPdfName] = useState(props.location.state)
    const [Base64Data, setBase64Data] = useState("")
  
    useEffect(() => {
  
      async function getBase64() {
        console.log(GetPdfName)
        await axios.post('/getbase64', { Name: GetPdfName.Name })
          .then(res => {
            setBase64Data(res.data.base64[0].Base64)
          })
      }
      getBase64()
    })
  
    let Data = <h1>Waiting</h1>
  
    if (Base64Data) {
      Data = (<div className='pdf-container'>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
          <Viewer fileUrl={Base64Data}
            plugins={[
              // Register plugins
              defaultLayoutPluginInstance
            ]}
          />
  
        </Worker>
      </div>)
    }
    else {
      Data = <LoadingScreen
        loading={true}
        bgColor='#f1f1f1'
        spinnerColor='#9ee5f8'
        textColor='#676767'
        logoSrc={LoadingLogo}
        text='Sultan Jamil Naseem'>
      </LoadingScreen>
    }
  
    return (
      <div className="App">
        <div>{Data}</div>
      </div>
    )
  }
  
  export default Books