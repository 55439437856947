import React from "react"
import {Nav,Navbar,Container} from 'react-bootstrap'
import './layout.css'

const Navbarr = () => {
  return(
    <Container>
    <Navbar expand="lg" variant="light" absolute="top" className="container">
  <Navbar.Brand href="/"><h1 className="logo">سلطان جمیل نسیم</h1></Navbar.Brand>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="Nav-items" activeKey="/home" variant="light">
    <Nav.Item>
        <Nav.Link href="/Saba-Akbar-Abadi"eventKey="link-1">صبا اکبرآبادی</Nav.Link>
      </Nav.Item>
    <Nav.Item>
        <Nav.Link href="/Videos"eventKey="link-1">ویڈیو</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href="/Drama-o-mazameen"eventKey="link-1">ڈارمے و مضامین</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href="/Afsana-o-Novel"eventKey="link-1">افسانے و ناول</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href="/ArtGallery" eventKey="link-2">تصاویر</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link href="/Taruf">تعارف</Nav.Link>
      </Nav.Item>
    </Nav>
     </Navbar.Collapse>
    </Navbar>
    </Container>

  )
  }

export default Navbarr  